* {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  margin: 0;
}

.circle {
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  border: 2px solid #666;
  color: white;
  border-color: white;
  margin: 1%;
}

.circleImage {
  /* width: 50%;  */
  /* height: 100%;  */
  height: 400px;
  width: 400px;
  border-radius: 50rem;
  object-fit: cover;
  /* padding-left: 200px;
  padding-right: 200px;
  margin-left: 200px;
  margin-right: 200px; */

  /* padding-right: 10%; */
  /* padding: 5%; */
}

.zIndex1 {
  z-index: 1;
}

.image-box {
  height: 400px;
  width: 400px;
  margin-left: 20px;
  margin-right: 20px;
}

.nothing {
}

.spacer {
  aspect-ratio: 960/300;
  width: 100%;
  /* height: -100px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* margin: "-100%"; */
}

.spacer-image {
  /* aspect-ratio: 960/300; */
  width: 100%;
  /* height: 80vh; */
  /* height: -100px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* margin: "-100%"; */
  /* overflow: hidden; */
  background-image: url("./assets/wave-spacer.svg");
  /* background-image: url("../assets/wave-spacer.svg"); */
}

.spacer-image-2 {
  /* aspect-ratio: 960/300; */
  width: 100%;
  /* height: 70vh; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* margin: "-100%"; */

  background-image: url("./assets/wave-spacer-3.svg");
  /* background-image: url("../assets/wave-spacer-3.svg"); */
  /* z-index: -2; */
}

.flip {
  transform: rotate(180deg);
}

.wave-motion {
  max-height: 80px;
  max-width: 100%;
  transform: rotate(180deg);
  overflow: hidden;
  position: absolute;
  /* margin-bottom: -100%; */
  z-index: 0;
  /* opacity: 40%; */
}
.wave-motion-2 {
  max-height: 60px;
  max-width: 110%;
  transform: rotate(180deg);
  overflow: hidden;
  position: absolute;
  z-index: 1;

  /* margin-bottom: -100%; */

  /* opacity: 40%; */
}

.wave-motion-3 {
  max-height: 40px;
  max-width: 100%;
  transform: rotate(180deg);
  overflow: hidden;
  position: absolute;
  z-index: 2;

  /* margin-bottom: -100%; */

  /* opacity: 40%; */
}

.header-space {
  padding: 100px;
  margin: 100px;
}

/* 

.hidden {
  opacity: 0;
  transition: all 1s;

}

.show {
    opacity: 1;
}


.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: tranlsateX(-100%)
  transition: all 1s;
}

.show {
    opacity: 1;
    filter blur(0);
    transorm: translateX(0);
}

*/

.noMargin {
  margin: 0;
}

.slot {
  width: 50%;
}
.empty {
  padding: 3%;
  margin: 3%;
}

.timelineCard {
  /* width: 100%; */
  padding: 3%;
  margin: 3%;
  background-color: #cfe8fc;
  /* background-color: white; */
  border-radius: 25px;
}

.timelinePoint::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  /* right: -17px;  */
  right: 0px;
  background-color: white;
  border: 4px solid #00a0a0;
  /* top: 15px; */
  border-radius: 50%;
  z-index: 1;
  left: 50%;
  transform: translateX(-15px);
  transform: translateY(-70px);
  /* transform: translate(-15px, -40px); */
  /* margin-top: 5%; */
}
.timelinePoint1::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  /* right: -17px;  */
  right: 0px;
  background-color: white;
  border: 4px solid orange;
  /* top: 15px; */
  border-radius: 50%;
  z-index: 1;
  left: 50%;
  /* transform: translateX(-15px); */
  transform: translate(-15px, -50px);

  /* margin-top: 5% */
}

.timeline {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  background-color: #cfe8fc;

  overflow: hidden;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.projectCard {
  max-width: 35%;

  margin: 2%;

  border-radius: 5%;

  /* border-bottom-style:ridge; */

  box-shadow: 2px 2px 5px;

  /* width: 35%; */

  transition: box-shadow 1s ease;
}

.projectCard:hover {
  box-shadow: 5px 5px 10px;
}

.contact {
  /* transform: translateY(20px); */

  margin-top: 5%;
}

/* @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap'); */

.nameTitle {
  font-family: "Dancing Script", cursive;
  font-size: 6em;
  padding: 2.5%;
  /* text-align: center; */
  overflow: hidden;
}

.title {
  font-family: "Dancing Script", cursive;
  font-size: 4em;
  overflow: hidden;
}

.personAnimation {
  width: 60%;
  height: 60%;
}

.aboutInfo {
  width: 45%;
  margin: 5%;
}

.about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  background-color: #cfe8fc;
}

.about-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 45%;
  margin: 5%;
}

.icon-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
}

.social-icons {
  display: flex;
  justify-content: space-around;
  /* margin-top: 15%; */
}

.icon-link {
  display: inline-block;
  margin: 3%;
  /* margin: 0 10px; */
  transition: transform 1s ease, color 1s ease;
  transform-origin: center; /* Set the transform origin to the center of the icon */
  color: black;
}

.icon-link:hover {
  transform: scale(1.5); /* Increase the scale factor as needed */
  /* color: green; */
  color: #50adf7;
  /* color: #2719e0; */
}

.skills-container {
  display: flex;
  flex-direction: row; /* Default value, you can use "column" for vertical stacking */
  justify-content: space-between; /* Distribute items evenly between columns */

  width: 45%;
}

.column {
  flex: 1; /* Each column takes equal width */
  padding: 10px; /* Add some space between images */
  /* text-align: center; */
  /* Center-align the images within each column */

  justify-content: space-around;
}

@media (max-width: 900px) {
  .nameTitle {
    /* font-size: 400%; */
    font-size: 3em;
  }

  .spacer-image {
    height: 80vh;
  }

  .aboutInfo {
    width: 100%;
    margin-left: 15%;
  }
  .about-container {
    flex-direction: column;
    justify-content: center;
  }

  .about-info-container {
    width: 100%;
    margin-left: 15%;
  }

  /* .imageBox {
    width: 100%;
  } */

  .personAnimation {
    /* width: 250px;
    height: 250px;    */

    /* width: 100%;
    height: 80%; */

    width: 80%;
    height: 80%;
  }

  /* .circleImage { */
  /* width: 200px; 
    height: 200px;  */
  /* min-width: 80%; */
  /* border-radius: 50rem;  */
  /* object-fit: cover; */
  /* } */

  .projectCard {
    min-width: 80%;
  }

  .empty {
    width: 0;
    padding: 0;
    margin: 0;
  }

  .left {
    /* transform: translateX(30px); */
    margin-left: 10%;
  }

  .right {
    /* transform: translateX(-30px) */
    margin-right: 9%;
  }

  .timeline::after {
    left: 15px;
  }

  .timelinePoint::after {
    left: 15px;
  }

  .timelinePoint1::after {
    left: 15px;
  }

  .timelineCard {
    /* margin-left: 10%; */
    /* width: 75%; */
  }

  /* Full-width containers */
  .container {
    width: 80%;
    padding-left: 70px;
    padding-right: 25x;
    margin-right: 100px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}

.curtain-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateY(-100%);
  transition: transform 2s ease-in-out;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}

.curtain-overlay .top-section {
  flex: 3;
  background: linear-gradient(to bottom, #00a0a0, #50adf7);
}

.curtain-overlay .bottom-section {
  flex: 1;
  background: transparent;
}

.curtain-overlay.visible {
  transform: translateY(0);
}

.dissolve-effect {
  transition: opacity 1.5s ease-in-out;
  opacity: 1;
}

.dissolve-effect.hidden {
  opacity: 0;
}
